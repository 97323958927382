import { Card, Col, Row, Tag } from 'antd'
import React from 'react'

function CurrencyShowComponent({data}) {
  return (
    <Card>
      <Row>
             <Col xs={24} sm={12} md={8} lg={6} xl={6}>
             <p className="fs-12 mb-10">Currency</p>
             <Tag label="Currency">{data.currency}</Tag>
           </Col>
 
           <Col xs={24} sm={12} md={8} lg={6} xl={6}>
             <p className="fs-12 mb-10">Amount</p>
             <Tag label="Amount">{data.amount}</Tag>
           </Col>
 
           <Col xs={24} sm={12} md={8} lg={6} xl={6}>
             <p className="fs-12 mb-10">Rate</p>
             <Tag label="Rate">{data.rate}</Tag>
           </Col>
 
           <Col xs={24} sm={12} md={8} lg={6} xl={6}>
             <p className="fs-12 mb-10" style={{fontWeight: 'bold'}}>Total</p>
             <Tag label="Total">
               {(parseFloat(data.amount) * parseFloat(data.rate)).toFixed(2)}
             </Tag>
             </Col>
             </Row>
    </Card>
  )
}

export default CurrencyShowComponent