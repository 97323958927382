import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  message,
  Col,
  Card,
  notification,
} from 'antd';
import {
  MinusCircleOutlined,
  FileTextOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import userService from '../../services/user';
import equipmentService from '../../services/equipments';
import equipmentRequestService from '../../services/equipmentRequest';
import DefaultLayout from '../../components/layout/DefaultLayout';
import Header from '../../components/header';
import './index.css';
import InventoryAssetComponent from '../../components/InventoryAsset';
import EquipmentCalculatComponent from '../../components/EquipmentCalculate';
import LocationComponent from '../../components/location';
import CostCenterComponent from '../../components/costCenter';

const PostEquipmentRequest = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useSelector((state) => state.user);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editingEquipmentRequest, setEditingEquipmentRequest] = useState(null);
  const [isDraft, setIsDraft] = useState(null);
  const defaultLocationId = currentUser ? currentUser.location.id : null;

  const { id } = useParams();
  const navigate = useNavigate();

  const handleRequirementChange = (index, values) => {
    setSelectedCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index] = values;
      return newCheckboxes;
    });
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          setLoading(true);
          setEditMode(true);
          const response = await equipmentRequestService.getEquipmentsRequestById(id);
          console.log(response);

          if (
            (response && response.draft_at !== null) ||
            (response && response.status === 'Return') ||
            (response && response.status !== 'Approved') ||
            (response && response.status !== 'Disapproved')
          ) {
            //setEditMode(true);
            setEditingEquipmentRequest(response);
            setLoading(false);
            console.log('yeh response ', response);
            form.setFieldsValue({
              request_title: response.request_title,
              location_id: response.location.id,
              cost_center_id: response.cost_center_id.id,
            });
            if (response && response.equipmentRequests && response.equipmentRequests.length !== 0) {
              form.setFieldsValue({
                equipment_requests: response.equipmentRequests.map(
                  (equipmentRequest) => ({
                    equipment_id: equipmentRequest.equipment.id,
                    quantity: equipmentRequest.qty,
                    state: equipmentRequest.state.id,
                    expense_type: equipmentRequest.expense_type.id,
                    expense_nature: equipmentRequest.expense_nature.id,
                    business_justification: equipmentRequest.business_justification,
                    currency: equipmentRequest.currency,
                    amount: equipmentRequest.amount,
                    rate: equipmentRequest.rate,
                    asset_details: equipmentRequest.asset_details && JSON.parse(equipmentRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
            
            if (
              response &&
              response.softwareRequests &&
              response.softwareRequests.length !== 0
            ) {
              form.setFieldsValue({
                software_requests: response.softwareRequests.map(
                  (softwareRequest) => ({
                    software_name: softwareRequest.name,
                    version: softwareRequest.version,
                    quantity: softwareRequest.qty,
                    expense_type: softwareRequest.expense_type.id,
                    expense_nature: softwareRequest.expense_nature.id,
                    business_justification: softwareRequest.business_justification,
                    currency: softwareRequest.currency,
                    amount: softwareRequest.amount,
                    rate: softwareRequest.rate,
                    asset_details: softwareRequest.asset_details && JSON.parse(softwareRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
            if (
              response &&
              response.serviceRequests &&
              response.serviceRequests.length !== 0
            ) {
              form.setFieldsValue({
                service_requests: response.serviceRequests.map(
                  (serviceRequest) => ({
                    service_name: serviceRequest.name,
                    state: serviceRequest.state.id,
                    expense_type: serviceRequest.expense_type.id,
                    expense_nature: serviceRequest.expense_nature.id,
                    business_justification: serviceRequest.business_justification,
                    currency: serviceRequest.currency,
                    amount: serviceRequest.amount,
                    rate: serviceRequest.rate,
                    asset_details: serviceRequest.asset_details && JSON.parse(serviceRequest.asset_details).map((assetDetail) => ({
                      action: assetDetail.action,
                      request_type: assetDetail.request_type,
                      inventory_status: assetDetail.inventory_status,
                      expected_expense: assetDetail.expected_expense,
                      serial_no: assetDetail.serial_no,
                      asset_code: assetDetail.asset_code,
                      description: assetDetail.description,
                      remarks: assetDetail.remarks,
                    })),
                  })
                ),
              });
            }
          } else {
            navigate('/crf');
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500) {
          navigate('/crf');
        }
        else {
          console.log(error);
        notification.error({
          message: 'Error fetching Capital Request Form',
          description: error,
        });
        }
        
      }
    };
    fetchData();
  }, [id, form]);

  const convertAssetDetailsToString = (assetDetailsArray) => {
    return JSON.stringify(assetDetailsArray.map(assetDetail => ({
      request_type: assetDetail.request_type,
      action: assetDetail.action,
      inventory_status: assetDetail.inventory_status,
      expected_expense: assetDetail.expected_expense,
      serial_no: assetDetail.serial_no,
      asset_code: assetDetail.asset_code,
      description: assetDetail.description,
      remarks: assetDetail.remarks,
    })));
  };
  const onFinish = async (values) => {

    const { equipment_requests, software_requests, service_requests } = values;
    if (
      (!equipment_requests || equipment_requests.length === 0) &&
      (!software_requests || software_requests.length === 0) &&
      (!service_requests || service_requests.length === 0)
    ) {
      message.error('Please fill out at least one form (equipment, software, or service request)');
      return;
    }

    try {
      values.save_as_draft = String(isDraft);
      //console.log(values)
      
      const equipmentRequestsArray = Array.isArray(values.equipment_requests) ? values.equipment_requests : [];
      const formattedEquipmentRequestsArray = equipmentRequestsArray.map(equipmentRequest => ({
        ...equipmentRequest,
        asset_details: convertAssetDetailsToString(equipmentRequest?.asset_details || []),
      }));

      const softwareRequestsArray = Array.isArray(values.software_requests) ? values.software_requests : [];
      const formattedSoftwareRequestsArray = softwareRequestsArray.length > 0
        ? softwareRequestsArray.map(softwareRequest => ({
          ...softwareRequest,
          asset_details: convertAssetDetailsToString(softwareRequest?.asset_details || []),
        }))
        : null;

      const serviceRequestsArray = Array.isArray(values.service_requests) ? values.service_requests : [];
      const formattedServiceRequestsArray = serviceRequestsArray.length > 0
        ? serviceRequestsArray.map(serviceRequest => ({
          ...serviceRequest,
          asset_details: convertAssetDetailsToString(serviceRequest?.asset_details || []),
        }))
        : null;

      const formattedData = {
        ...values, 
        equipment_requests: formattedEquipmentRequestsArray,
        software_requests: formattedSoftwareRequestsArray,
        service_requests: formattedServiceRequestsArray,
        save_as_draft: values.save_as_draft,
      };

      //console.log(JSON.stringify(formattedData))

      if (editMode) {
        const response = await equipmentRequestService.updateEquipmentRequest(
          editingEquipmentRequest.id,
          formattedData
        );

        if (response.success) {
          setEditMode(false);
          setEditingEquipmentRequest(null);
          notification.success({
            message: 'Equipment Updated',
            description: response.message,
            
          });
          navigate('/crf');
        }
      } else {
        const response = await equipmentRequestService.postEquipmentRequest(
          formattedData
        );

        if (response.success) {
          form.resetFields();
          notification.success({
            message: 'Equipment Added',
            description: response.message,
          });
          navigate('/crf');
        }
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Equipment Operation Failed',
        description: error.response.data.message,
      });
    }
  };

  const getAllEquipmentData = async () => {
    try {
      const response = await equipmentService.getAllEquipments();
      setEquipmentData(response);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.response,
      });
    }
  };

  useEffect(() => {
    getAllEquipmentData();
  }, []);

  const getCurrentUser = async () => {
    setLoading(true);
    try {
      const response = await userService.getCurrentUser(currentUser.user_id);
      //console.log(response);
      form.setFieldsValue({
        name: response.name,
        email: response.email,
        designation_id: response.designation.name,
        employee_no: response.employee_no,
        employee_type: response.employee_type,
        extension: response.extension,
        phone_number: response.phone_number,
        profile_location: response.location.name,
        role_id: response.roles.map((role) => role.id),
        department_id: response.department.name,
        section_id: response.section.name,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const validateCheckboxGroup = (_, values) => {
    if (values && values.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please select at least one requirement.'));
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header icon={<FileTextOutlined />} title="Capital Request Form" />
        </Col>
      </Row>
      <Card>
        <Form
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          scrollToFirstError
          className="mt-20"
        >
          <div spinning={loading}>
           

            <Card size="small" className="mb-10" title="Request Title">
              <Row gutter={[12, 12]}>
                <Col lg={8} md={12} sm={24} xs={24}>
                  <Form.Item
                    name="request_title"
                    label="Request Title"
                    rules={[{ required: true, message: 'Type Request Title' }]}
                  >
                    <Input placeholder="Type Request Title" size="large" />
                  </Form.Item>
                </Col>

                <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name="location_id"
                  label="Location"
                  rules={[
                    { required: true, message: 'Please select a location' },
                  ]}
                  initialValue={defaultLocationId}

                >
                
                    <LocationComponent all={true} />
                </Form.Item>

              </Col>
                <Col lg={8} md={12} sm={24} xs={24}>
                <Form.Item
                  name="cost_center_id"
                  label="Cost Center"
                  rules={[
                    { required: true, message: 'Please select a cost center' },
                  ]}
                  // initialValue={defaultLocationId}

                >

                    <CostCenterComponent related={true} />
                </Form.Item>

              </Col>
              </Row>
            </Card>

            <Form.List name="equipment_requests">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => {
                      const isEditMode = editMode && editingEquipmentRequest;

                      return (
                        <>
                          <Card
                            key={key}
                            size="small"
                            className="mb-10"
                            title={`Equipment Request Form ${index + 1}`}
                            extra={
                              <Button
                                type="danger"
                                icon={<MinusCircleOutlined />}
                                onClick={() => {
                                  remove(name);
                                  setSelectedCheckboxes((prev) =>
                                    prev.filter((_, i) => i !== index)
                                  );
                                }}
                              >
                                Remove
                              </Button>
                            }
                          >
                            <Row gutter={[12, 12]}>
                              <Col
                                lg={12}
                                md={12}
                                sm={24}
                                xs={24}
                                className="mb-10"
                              >
                                <Form.Item
  label="Requirement"
  name={[name, 'equipment_id']}
  rules={[
    {
      required: true,
      message: 'Please select a requirement!',
    },
  ]}
>
  <Select
    size="large"
    onChange={(value) => handleRequirementChange(index, value)}
  >
    {equipmentData.map((equipment) => (
      <Select.Option key={equipment.name} value={equipment.id}>
        {equipment.name}
      </Select.Option>
    ))}
  </Select>
</Form.Item>

                              </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                              <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                  name={[name, `quantity`]}
                                  label={`Quantity`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Enter Quantity',
                                    },
                                  ]}
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </Col>
                              <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                  name={[name, `state`]}
                                  label={`Equipment State`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Equipment State',
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    size="large"
                                    placeholder={`Select Equipment Type`}
                                  >
                                    <Select.Option value={1}>New</Select.Option>
                                    {/* <Select.Option value={2}>Renew</Select.Option> */}
                                    <Select.Option value={3}>
                                      Repair
                                    </Select.Option>
                                    <Select.Option value={4}>
                                      Replace
                                    </Select.Option>
                                    <Select.Option value={5}>
                                      Temporary
                                    </Select.Option>
                                    <Select.Option value={6}>
                                      Upgrade
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                  name={[name, `expense_type`]}
                                  label={`Expenses Type`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Expenses Type',
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    size="large"
                                    placeholder={`Select Expenses Type`}
                                  >
                                    <Select.Option value={1}>
                                      Budgeted
                                    </Select.Option>
                                    <Select.Option value={2}>
                                      Non Budgeted
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col lg={6} md={6} sm={24} xs={24}>
                                <Form.Item
                                  name={[name, `expense_nature`]}
                                  label={`Expense Nature`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select Expense Nature',
                                    },
                                  ]}
                                >
                                  <Select
                                    showSearch={true}
                                    optionFilterProp="children"
                                    size="large"
                                    placeholder={`Select Expense Nature`}
                                  >
                                    <Select.Option value={1}>
                                      Capital
                                    </Select.Option>
                                    <Select.Option value={2}>
                                      Revenue
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                              <Col
                                lg={24}
                                md={24}
                                sm={24}
                                xs={24}
                                className="mb-10"
                              >
                                <Form.Item
                                  label={`Business Justification`}
                                  name={[name, `business_justification`]}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Enter Business Justification',
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    rows={4}
                                    size="large"
                                    placeholder={`Enter Business Justification`}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            
            {editMode && <EquipmentCalculatComponent name={name} />}        
            {editMode && <InventoryAssetComponent index={index} request_type="equipment_requests" />}

                          </Card>
                        </>
                      );
                    }
                  )}
                  <Form.Item style={{ margin: '10px' }}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                        setSelectedCheckboxes((prev) => [...prev, []]);
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add Equipment Request Form
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>

            <Form.List name="software_requests">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Card
                        key={key}
                        size="small"
                        className="mb-10"
                        title={`Software Request Form ${index + 1}`}
                        extra={
                          <Button
                            type="danger"
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(name)}
                          >
                            Remove
                          </Button>
                        }
                      >
                        <Row gutter={[12, 12]}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `software_name`]}
                              label="Software Name"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Software Name',
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Software Name"
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `version`]}
                              label="Software Version"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Software Version',
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Software Version"
                              />
                            </Form.Item>
                          </Col>

                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `quantity`]}
                              label="Software Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter Software Quantity',
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Software Quantity"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[12, 12]}>
                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `expense_type`]}
                              label="Expenses Type"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a Expenses Type',
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                optionFilterProp="children"
                                size="large"
                                placeholder="Select Expenses Type"
                              >
                                <Select.Option value={1}>
                                  Budgeted
                                </Select.Option>
                                <Select.Option value={2}>
                                  Non Budgeted
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `expense_nature`]}
                              label="Expense Nature"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a Expense Nature',
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                optionFilterProp="children"
                                size="large"
                                placeholder="Select Expense Nature"
                              >
                                <Select.Option value={1}>Capital</Select.Option>
                                <Select.Option value={2}>Revenue</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[12, 12]}>
                          <Col
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="mb-10"
                          >
                            <Form.Item
                              name={[name, `business_justification`]}
                              label="Business Justification"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please enter Business Justification',
                                },
                              ]}
                            >
                              <Input.TextArea
                                rows={4}
                                size="large"
                                placeholder="Enter Business Justification"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

          {editMode && <EquipmentCalculatComponent name={name} />}
          {editMode && <InventoryAssetComponent index={index} request_type="software_requests" /> }

                      </Card>
                    )
                  )}

                  <Form.Item style={{ margin: '10px' }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Software Request Form
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>

            <Form.List name="service_requests">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <Card
                        key={key}
                        size="small"
                        className="mb-10"
                        title={`Service Request Form ${index + 1}`}
                        extra={
                          <Button
                            type="danger"
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(name)}
                          >
                            Remove
                          </Button>
                        }
                      >
                        <Row gutter={[12, 12]}>
                          <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `service_name`]}
                              label="Required Service (Nature)"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please enter Required Service (Nature)',
                                },
                              ]}
                            >
                              <Input
                                size="large"
                                placeholder="Enter Required Service (Nature)"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[12, 12]}>
                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `state`]}
                              label="Service State"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a Service State',
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                optionFilterProp="children"
                                size="large"
                                placeholder="Select Service Type"
                              >
                                <Select.Option value={1}>New</Select.Option>
                                <Select.Option value={2}>Renewal</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `expense_type`]}
                              label="Expenses Type"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a Expenses Type',
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                optionFilterProp="children"
                                size="large"
                                placeholder="Select Expenses Type"
                              >
                                <Select.Option value={1}>
                                  Budgeted
                                </Select.Option>
                                <Select.Option value={2}>
                                  Non Budgeted
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col lg={8} md={8} sm={24} xs={24}>
                            <Form.Item
                              name={[name, `expense_nature`]}
                              label="Expense Nature"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a Expense Nature',
                                },
                              ]}
                            >
                              <Select
                                showSearch={true}
                                optionFilterProp="children"
                                size="large"
                                placeholder="Select Expense Nature"
                              >
                                <Select.Option value={1}>Capital</Select.Option>
                                <Select.Option value={2}>Revenue</Select.Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[12, 12]}>
                          <Col
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="mb-10"
                          >
                            <Form.Item
                              name={[name, `business_justification`]}
                              label="Business Justification"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    'Please enter Business Justification',
                                },
                              ]}
                            >
                              <Input.TextArea
                                rows={4}
                                size="large"
                                placeholder="Enter Business Justification"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

            {editMode && <EquipmentCalculatComponent name={name} />}
            {editMode && <InventoryAssetComponent index={index} request_type="service_requests" /> }

                        
                      </Card>
                    )
                  )}

                  <Form.Item style={{ margin: '10px' }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Service Request Form
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>

            <Row gutter={[12, 12]} className="mt-40">
              <Col span={24} className="text-right">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  className="mt-24"
                >
                  <Button
                    type="primary"
                    block
                    disabled={loading} 
                    loading={loading}
                    className="btn-blue mr-20"
                    onClick={() => {
                      setIsDraft(false);
                      form.submit();
                    }}
                  >
                    Save & Publish
                  </Button>

                  <Button
                  disabled={loading} 
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      setIsDraft(true);
                      form.submit();
                    }}
                  >
                    Save Draft
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Card>
    </DefaultLayout>
  );
};

export default PostEquipmentRequest;
