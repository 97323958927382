import api from '../libs/api';

export default {

  postWorkflow: (data) => {
    return new Promise((resolve, reject) => {
        api()
            .post('/workflows', data)
            .then(({ status, data }) => {
                if (status === 201) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  deleteWorkflow: (id) => {
    return new Promise((resolve, reject) => {
        api()
            .delete(`/workflows/${id}`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  updateWorkflow: (id, data) => {
    return new Promise((resolve, reject) => {
        api()
            .put(`/workflows/${id}`, data)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getWorkflowDetails: (id) => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/workflows/${id}`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getWorkflows: () => {
    return new Promise((resolve, reject) => {
        api()
            .get('/workflows')
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

}