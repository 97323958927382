import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import departmentService from '../../services/department'; 

export default function DepartmentComponent({ onChange, value, all }) {
  const [departments, setDepartments] = useState(null);

  const getDepartments = async () => {
    let response;
    if (all === true) {
      response = await departmentService.getAllDepartments();
    } else {
      response = await departmentService.getDepartments();
    }
    setDepartments(response);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <Select
      showSearch={true}
      optionFilterProp="children"
      style={{ width: '100%' }}
      size="large"
      placeholder="Please Select Department"
      value={value}
      onChange={onChange}
      allowClear
    >
      {departments &&
        departments.map((department) => (
          <Select.Option key={department.id} value={department.id}>
            {department.name}
          </Select.Option>
        ))}
    </Select>
  );
}
