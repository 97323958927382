import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { getBackups } from '../../services/backup';

const BackupList = () => {
  const [backups, setBackups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBackups = async () => {
      const backupData = await getBackups();
      setBackups(backupData.data);
      setLoading(false);
    };

    fetchBackups();
  }, []);

  const columns = [
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={backups}
      loading={loading}
      rowKey={(record) => record.path}
    />
  );
};

export default BackupList;
