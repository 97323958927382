import React from 'react';
import { Space } from 'antd';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TableActionBtnsComponent = ({ record, handleDelete }) => {
  const { currentUser } = useSelector((state) => state.user);

  // Check if the record is valid before accessing properties
  if (!record || !record.created_by) {
    return null; // Or return a placeholder if needed
  }

  /*const isCurrentUserApprover = record.approvers.some((approver) =>
    approver.users.some(
      (user) => user.id === currentUser.user_id && user.editable === true
    )
  ); */

  return (
    <Space>
      <Link to={'details/' + record.id}>
        <EyeOutlined />
      </Link>

      {/* Check record.draft_at existence before comparison */}
      {record.created_by.id === currentUser.user_id && 
       (record.status === 'Return' || (record.draft_at !== null && record.draft_at !== undefined)) ? (
        <Link to={`edit/${record.id}`} style={{ cursor: 'pointer' }}>
          <EditOutlined />
        </Link>
      ) : null}

      {/* Simplified draft_at check using optional chaining */}
      {record.draft_at?.length > 0 || record.status === 'Return' ? (
        <Link
          onClick={() => handleDelete(record)}
          style={{ cursor: 'pointer' }}
        >
          <DeleteOutlined />
        </Link>
      ) : null}
    </Space>
  );
};

export default TableActionBtnsComponent;