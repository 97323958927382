import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, Input, Row, Col, Card} from 'antd';
import { TeamOutlined} from '@ant-design/icons';
import DefaultLayout from '../../components/layout/DefaultLayout';
import Header from '../../components/header';
import BackupList from '../../components/backup';


const Backup = () => {

  return (
    <DefaultLayout>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header
            icon={<TeamOutlined/>}
            title="Backups"

          />
        </Col>
      </Row>

     <Card>
     <BackupList />
     {/* <Table
         style={{ minHeight: '100vh' }}
  columns={columns}
  dataSource={data.map((item) => ({ ...item, key: item.id }))}
  pagination={{
    total: totalDataCount,
    current: currentPage,
    pageSize: itemsPerPage,
    onChange: (page, pageSize) => getDepartments(page),
  }}

  loading={loading}
/> */}
     </Card>


    </DefaultLayout>
  );
};
  // return (
  //   <div>
  //     <h1>Backups</h1>
  //     <BackupList />
  //   </div>
  // );


export default Backup;
